export const muggerNames = [
    "Paul McHeartney",
    "Axl Toes",
    "Flank Williams",
    "Clarence Marrow",
    "Eva Lungoria",
    "Liver Phoenix",
    "Kevin Bacon",
    "Corey Heart",
    "Jessica Elbow",
    "Jerry Seinflank",
    "Amelia Earandheart",
    "Darryl Strawberry",
    "Flank Zappa",
    "Richard Smokehouse Nixon",
];
